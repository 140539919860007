export { wrapRootElement } from "./gatsby-shared";


export const onRouteUpdate = ({ location, prevLocation }) => {
    if (
        location.pathname === "/properties/" ||
        location.pathname === "/properties"
      ) {
        window.location.replace("/properties/for-sale/")
      }
}