exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-commercial-properties-js": () => import("./../../../src/pages/commercial-properties.js" /* webpackChunkName: "component---src-pages-commercial-properties-js" */),
  "component---src-pages-off-plan-properties-js": () => import("./../../../src/pages/off-plan-properties.js" /* webpackChunkName: "component---src-pages-off-plan-properties-js" */),
  "component---src-pages-properties-js": () => import("./../../../src/pages/properties.js" /* webpackChunkName: "component---src-pages-properties-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-areaguide-details-template-js": () => import("./../../../src/templates/areaguide-details-template.js" /* webpackChunkName: "component---src-templates-areaguide-details-template-js" */),
  "component---src-templates-areaguide-landing-js": () => import("./../../../src/templates/areaguide-landing.js" /* webpackChunkName: "component---src-templates-areaguide-landing-js" */),
  "component---src-templates-career-details-js": () => import("./../../../src/templates/career-details.js" /* webpackChunkName: "component---src-templates-career-details-js" */),
  "component---src-templates-career-landing-template-js": () => import("./../../../src/templates/career-landing-template.js" /* webpackChunkName: "component---src-templates-career-landing-template-js" */),
  "component---src-templates-contact-template-js": () => import("./../../../src/templates/contact-template.js" /* webpackChunkName: "component---src-templates-contact-template-js" */),
  "component---src-templates-event-template-js": () => import("./../../../src/templates/event-template.js" /* webpackChunkName: "component---src-templates-event-template-js" */),
  "component---src-templates-form-template-js": () => import("./../../../src/templates/form-template.js" /* webpackChunkName: "component---src-templates-form-template-js" */),
  "component---src-templates-guide-details-js": () => import("./../../../src/templates/guide-details.js" /* webpackChunkName: "component---src-templates-guide-details-js" */),
  "component---src-templates-guide-landing-template-js": () => import("./../../../src/templates/guide-landing-template.js" /* webpackChunkName: "component---src-templates-guide-landing-template-js" */),
  "component---src-templates-home-template-js": () => import("./../../../src/templates/home-template.js" /* webpackChunkName: "component---src-templates-home-template-js" */),
  "component---src-templates-investor-template-js": () => import("./../../../src/templates/investor-template.js" /* webpackChunkName: "component---src-templates-investor-template-js" */),
  "component---src-templates-landing-template-js": () => import("./../../../src/templates/landing-template.js" /* webpackChunkName: "component---src-templates-landing-template-js" */),
  "component---src-templates-news-details-template-js": () => import("./../../../src/templates/news-details-template.js" /* webpackChunkName: "component---src-templates-news-details-template-js" */),
  "component---src-templates-news-landing-template-js": () => import("./../../../src/templates/news-landing-template.js" /* webpackChunkName: "component---src-templates-news-landing-template-js" */),
  "component---src-templates-offplan-detail-template-js": () => import("./../../../src/templates/offplan-detail-template.js" /* webpackChunkName: "component---src-templates-offplan-detail-template-js" */),
  "component---src-templates-property-details-js": () => import("./../../../src/templates/property-details.js" /* webpackChunkName: "component---src-templates-property-details-js" */),
  "component---src-templates-static-template-js": () => import("./../../../src/templates/static-template.js" /* webpackChunkName: "component---src-templates-static-template-js" */),
  "component---src-templates-team-details-js": () => import("./../../../src/templates/team-details.js" /* webpackChunkName: "component---src-templates-team-details-js" */),
  "component---src-templates-team-landing-template-js": () => import("./../../../src/templates/team-landing-template.js" /* webpackChunkName: "component---src-templates-team-landing-template-js" */)
}

